@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Epilogue:wght@200;300;500;600;700&family=Inter:wght@400;500;700&display=swap");
/* html {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
} */

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: visible;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  font-family: Arial, Sans-Serif;
}

body.ReactModal__Body--open {
  overflow-y: hidden;
}

/* .react-toast-notifications__container {
  margin-top: 64px;
} */
